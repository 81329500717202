<template>
    <div class="flex flex-col justify-start items-start">
        <span class="text-md font-semibold text-gray-900 dark:text-white"
            >Update Available</span
        >
        <span class="text-sm text-gray-900 dark:text-white"
            >Please update to the latest version!</span
        >
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'UpdateAvailable',
});
</script>
